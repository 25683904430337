/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import axios from 'axios'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  className: string
  items?: number
}

const ListsWidget4: React.FC<Props> = () => {

	const intl = useIntl()
  const API_URL = process.env.REACT_APP_BASE_URL
  const {currentUser} = useAuth()

  const [providers, setProviders] = useState([]);

  const fetchProviders = useCallback(async () => {
    await axios.post(`${API_URL}/getThirdPartySMSProviders`, { user_id: currentUser?.id, api_token: currentUser?.token, page:1, items_per_page:10} )
    .then(res4 => {
      if (res4?.data?.status === 'success') {
        setProviders(res4.data.data);
      } else {
        setProviders([]);
      }
    })
  }, [currentUser, API_URL]);

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  const providerImg =(id:any)=> {
		const gID = id;
		let imgSrc = "";
		if (gID === 1 || gID === "1") {
			imgSrc =  toAbsoluteUrl('/media/logos/infobip.png');
		} 
		if (gID === 2 || gID === "2") {
			imgSrc =  toAbsoluteUrl('/media/logos/clickatell.png');
		} 
		if (gID === 3 || gID === "3") {
			imgSrc =  toAbsoluteUrl('/media/logos/twillio.png');
		} 
		if (gID === 4 || gID === "4") {
			imgSrc =  toAbsoluteUrl('/media/logos/mumara.png');
		} 
		if (gID === 6 || gID === "6") {
			imgSrc =  toAbsoluteUrl('/media/logos/clicksend.png');
		}
		return imgSrc;
	}

  const providerSymbol =(id:any)=> {
		const pID = id;
		let symbol = "";
		if (pID === 1 || pID === "1") {
			symbol =  "bg-light-danger";
		} 
		if (pID === 2 || pID === "2") {
			symbol =  "bg-light-primary";
		} 
		if (pID === 3 || pID === "3") {
			symbol =  "bg-light-danger";
		} 
		if (pID === 4 || pID === "4") {
			symbol =  "bg-light-success";
		} 
		if (pID === 6 || pID === "6") {
			symbol =  "bg-light-primary";
		}
		return symbol;
	}

  return (
    <div className='card card-xl-stretch height-100'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>{intl.formatMessage({id: 'DASHBOARD.PROVIDERS'})}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{intl.formatMessage({id: 'DASHBOARD.PROVIDERSDESC'})}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0 max-height-410'>
        <div className='main-notification-block scroll-y pe-1'>

          {
            providers !== undefined && providers.length > 0 ?
            <>
            {/* {JSON.stringify(providers)} */}
              {
                providers?.map((i) => (
                  <div className='d-flex align-items-sm-center mb-7' key={i.id}>
                    <div className='symbol symbol-50px me-5'>
                      <span className={`symbol-label ${providerSymbol(i.gateway_id)}`}>
                        <img
                          src={providerImg(i.gateway_id)}
                          className='h-50 align-self-center'
                          alt={i.gateway_name}
                        />
                      </span>
                    </div>
                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                          {i.gateway_id === 1 || i.gateway_id === "1" ? "Infobip" : null}
                          {i.gateway_id === 2 || i.gateway_id === "2" ? "Clickatell" : null}
                          {i.gateway_id === 3 || i.gateway_id === "3" ? "Twilio" : null}
                          {i.gateway_id === 4 || i.gateway_id === "4" ? "Mumara SMS" : null}
                          {i.gateway_id === 6 || i.gateway_id === "6" ? "Click Send" : null}
                        </a>
                        <span className='text-muted fw-semibold d-block fs-7'>{i.gateway_name}</span>
                      </div>
                      <span className='badge badge-light fw-bold my-2'>{i.total_count !== null ? i.total_count : 0}</span>
                    </div>
                  </div>
                ))
              }
            </>
            : <div className='ra-no-data'> 
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon svg-icon-4x text-grey"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" fill="#6c757d"></path></svg> {intl.formatMessage({id: 'DASHBOARD.NODATA'})}
              </div>  
          }

                  {/* <div className='d-flex align-items-sm-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label'>
                        <img
                          src={providerImg(i.gateway_id)}
                          className='h-50 align-self-center'
                          alt={i.gateway_name}
                        />
                      </span>
                    </div>
                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                          {i.gateway_id === 1 || i.gateway_id === "1" ? "Infobip" : null}
                          {i.gateway_id === 2 || i.gateway_id === "2" ? "Clickatell" : null}
                          {i.gateway_id === 3 || i.gateway_id === "3" ? "Twilio" : null}
                          {i.gateway_id === 4 || i.gateway_id === "4" ? "Mumara SMS" : null}
                          {i.gateway_id === 6 || i.gateway_id === "6" ? "Click Send" : null}
                        </a>
                        <span className='text-muted fw-semibold d-block fs-7'>{i.gateway_name}</span>
                      </div>
                      <span className='badge badge-light fw-bold my-2'>{i.total_count !== null ? i.total_count : 0}</span>
                    </div>
                  </div> */}

          

        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget4}
