/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, useCallback, useRef} from 'react'
import {useIntl} from 'react-intl'
// import {PageTitle} from '../../../_metronic/layout/core'
import {getColorNameWithIndex} from '../../Colors/Colors'
import axios from 'axios'
import { useAuth } from '../../modules/auth'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Loader from '../../Loader/Loader'
import moment from 'moment'
import { StatisticsWidget3 } from '../../../_metronic/partials/widgets'
import { MixedWidget7 } from '../../../_metronic/partials/widgets'
import { ListsWidget4 } from '../../../_metronic/partials/widgets'
import Chart from '../../Component/Chart'
import Swal from 'sweetalert2'
import SvgGauge from 'svg-gauge'
import './dasboard.css'

const DashboardPage: FC = () => {

	const windowsWidth = window.innerWidth;

	const intl = useIntl()
	const color = getColorNameWithIndex;

	const API_URL = process.env.REACT_APP_BASE_URL
	const {currentUser, logout} = useAuth()
	const [logs, setLogs] = useState([]);
	const [activities, setActivities] = useState([]);
	const [loading, setLoading] = useState(true);

	const [balance, setBalance] = useState(0);
	const [list, setList] = useState(0);
	const [contacts, setContacts] = useState(0);
	const [totalContacts, setTotalContacts] = useState("0" || 0);
	const [broadcasts, setBroadcasts] = useState(0);
	const [campaigns, setCampaigns] = useState(0);
	const [totalCampaigns, setTotalCampaigns] = useState(0);
	const [sent, setSent] = useState(0);
	const [delivered, setDelivered] = useState(0);
	const [pending, setPending] = useState(0);
	const [cload, setCload] = useState(false);
	const [topNetworks, setTopNetworks] = useState({categories:[], data: []});
	const [topCountries, setTopCountries] = useState({categories:[], data: []});
	const [networkFlag, setNetworkFlag] = useState(false);
	const [countryFlag, setCountryFlag] = useState(false);
	
	const [chartStats, setChartStats] = useState({
		month: {
			categories: [],
			filter: [],
			stats: []
		},
		today: {
			categories: [],
			filter: [],
			stats: []
		},
		week: {
			categories: [],
			filter: [],
			stats: []
		},
		year: {
			categories: [],
			filter: [],
			stats: []
		}
	});
	const [l5campPrice, setL5campPrice] = useState([]);

	const fetchTC = useCallback(async () => {
		await axios.post(`${API_URL}/getUserTopCountries`, { user_id: currentUser?.id, api_token: currentUser?.token} )
		.then(res5 => {
			if(res5?.data?.status === 'false' && res5?.data?.code === 710) {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
				logout();
			} else if (res5?.data?.status === 'success') {
				setTopCountries(res5?.data?.data);
				if(res5?.data?.flag === 0) {
					setCountryFlag(false);
				} else {
					setCountryFlag(true);
				}
			} else {
				setTopCountries({categories:[], data: []});
				setCountryFlag(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if(error?.response?.status === 429 || error?.response?.status === "429") {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
			  	logout();  
			}
		  });
	}, [currentUser, API_URL, logout]);

	useEffect(() => {
		fetchTC();
	}, [fetchTC]);

	const fetchTN = useCallback(async () => {
		await axios.post(`${API_URL}/getUserTopNetworks`, { user_id: currentUser?.id, api_token: currentUser?.token} )
		.then(res5 => {
			if(res5?.data?.status === 'false' && res5?.data?.code === 710) {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
				logout();
			} else if (res5?.data?.status === 'success') {
				setTopNetworks(res5?.data?.data);
				if(res5?.data?.flag === 0) {
					setNetworkFlag(false);
				} else {
					setNetworkFlag(true);
				}
			} else {
				setTopNetworks({categories:[], data: []});
				setNetworkFlag(false);
			}
		}).catch(function (error) {
			setLoading(false);
			if(error?.response?.status === 429 || error?.response?.status === "429") {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
			  	logout();  
			}
		  });
	}, [currentUser, API_URL, logout]);

	useEffect(() => {
		fetchTN();
	}, [fetchTN]);

	const fetchCS = useCallback(async () => {
		await axios.post(`${API_URL}/getChartStats`, { user_id: currentUser?.id, api_token: currentUser?.token} )
		.then(res5 => {
			if(res5?.data?.status === 'false' && res5?.data?.code === 710) {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
				logout();
			} else if (res5?.data?.status === 'success') {
				setChartStats(res5?.data?.data);
			} else {
				setChartStats({month: {
					categories: [],
					filter: [],
					stats: []
				},
				today: {
					categories: [],
					filter: [],
					stats: []
				},
				week: {
					categories: [],
					filter: [],
					stats: []
				},
				year: {
					categories: [],
					filter: [],
					stats: []
				}});
			}
		}).catch(function (error) {
			setLoading(false);
			if(error?.response?.status === 429 || error?.response?.status === "429") {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
			  	logout();  
			}
		  });
	}, [currentUser, API_URL, logout]);

	useEffect(() => {
		fetchCS();
	}, [fetchCS]);

	const fetchSc = useCallback(async () => {
		await axios.post(`${API_URL}/getClientSentCounts`, { user_id: currentUser?.id, api_token: currentUser?.token} )
		.then(res4 => {
			if (res4?.data?.status === 'success') {
				setSent(res4?.data?.data?.sent);
				setDelivered(res4?.data?.data?.delivered);
				setPending(res4?.data?.data?.pending);
			} else {
				setSent(0);
				setDelivered(0);
				setPending(0);
			}
			setTimeout(() => {
				setCload(true);
			}, 1500);
		})
	}, [currentUser, API_URL]);

	useEffect(() => {
		fetchSc();
	}, [fetchSc]);
	
	const fetchBc = useCallback(async () => {
		await axios.post(`${API_URL}/getBroadcastCost`, { user_id: currentUser?.id, api_token: currentUser?.token} )
		.then(res3 => {
		if (res3?.data?.status === 'success') {
			setL5campPrice(res3?.data?.broadcast_expenses.reverse());
		} else {
			setL5campPrice([]);
			// console.log(res3.data.status);
		}
		})
	}, [currentUser, API_URL]);

	useEffect(() => {
		fetchBc();
	}, [fetchBc]);

	const fetchLc = useCallback(async () => {
		await axios.post(`${API_URL}/getUserLimits`, { user_id: currentUser?.id, api_token: currentUser?.token, page:1, items_per_page:10} )
		.then(res2 => {
		if (res2?.data?.status === 'success') {
			setList(res2?.data?.data?.total_lists);
			setContacts(res2?.data?.data?.total_contacts);
			setTotalContacts(res2?.data?.data?.contacts_limit);
			setBroadcasts(res2?.data?.data?.total_broadcases);
			setCampaigns(res2?.data?.data?.total_sent_campaigns);
			setTotalCampaigns(res2?.data?.data?.total_scheduled);
		} else {
			setList(0);
			// console.log(res3.data.status);
		}
		})
	}, [currentUser, API_URL]);

	useEffect(() => {
		fetchLc();
	}, [fetchLc]);

	const activityLogs = useCallback(async () => {
			
		await axios.post(`${API_URL}/getActivityLogs`, { user_id: currentUser?.id, api_token: currentUser?.token, page:1, items_per_page:10 }, { 
		}).then(dataRes => { // then print response status
			setLoading(false);
			if (dataRes?.data?.status === 'success') {
			setLogs(dataRes?.data?.data);
			setLoading(false);
			} else {
			setLogs([]);
			setLoading(false);
			}
		}).catch(function (error) {
		setLoading(false);
			if(error?.response?.status === 429 || error?.response?.status === "429") {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
				logout();
			}
		});
		await axios.post(`${API_URL}/getUserRecentActivities`, {user_id: currentUser?.id, api_token: currentUser?.token}, { 
		}).then(dataRes => { // then print response status
			setLoading(false);
			if (dataRes?.data?.status === 'success') {
				setActivities(dataRes?.data?.data);
				setLoading(false);
			} else {
				setActivities([]);
				setLoading(false);
			}
		}).catch(function (error) {
		setLoading(false);
			if(error?.response?.status === 429 || error?.response?.status === "429") {
				Swal.fire({
                    icon: 'warning',
                    title: 'Session expired, <br /> please login again to continue.<br /><br />',
                    showConfirmButton: false,
                    timer: 2000
                });
				logout();
			}
		});
	}, [currentUser, logout, API_URL]);

	const clientBalance = useCallback(async () => {
		await axios.post(`${API_URL}/getClientBalance`, { user_id: currentUser?.id, api_token: currentUser?.token, page:1, items_per_page:10 }, { 
		}).then(res => { // then print response status
			setLoading(false);
			if (res?.data?.status === 'success') {
			setBalance(res?.data?.data?.credit);
			localStorage.setItem("cr", calculate(res?.data?.data?.credit));
			setLoading(false);
			} else {
			setBalance(0);
			setLoading(false);
			}
		})
	}, [API_URL, currentUser]);

	useEffect(() => {
		activityLogs();
		clientBalance();
	}, [activityLogs, clientBalance]);

  	const lastIndex =(last)=> {
		const arryData = last;
		const lastElement = arryData.slice(-1);
		const lastTime = lastElement[0]?.created_at;
		const diff = moment(lastTime).fromNow(true);
		return diff;
	}

	const companies = [
		{ id: 1, name: 'Today', img: `${toAbsoluteUrl('/media/logos/company1.png')}` },
		{ id: 2, name: 'This Week', img: `${toAbsoluteUrl('/media/logos/company2.png')}` },
		{ id: 3, name: 'This Month', img: `${toAbsoluteUrl('/media/logos/company3.png')}` },
		{ id: 4, name: 'This Year', img: `${toAbsoluteUrl('/media/logos/company4.png')}` },
	];
	const COMPANIES_TAB = {
		COMP1: companies[0].name,
		COMP2: companies[1].name,
		COMP3: companies[2].name,
		COMP4: companies[3].name,
	};
	const [activeCompanyTab, setActiveCompanyTab] = useState(COMPANIES_TAB.COMP1);
  	const salesByStoreOptions = {
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		xaxis: {
			categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec',],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreSeriesData1 = chartStats !== undefined && chartStats?.today?.stats.length > 0 ? chartStats?.today?.stats: [];
	const salesByStoreOptionsChart1 = {
		label: '12AM - 3PM',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.today?.filter.length > 0 ? chartStats?.today?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.today?.categories.length > 0  ? chartStats?.today?.categories : [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Today)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Today)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Today)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Today)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Today)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreOptionsChart111 = {
		label: '12AM - 3PM',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.today?.filter.length > 0 ? chartStats?.today?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.today?.categories.length > 0  ? chartStats?.today?.categories : [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Today)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Today)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Today)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Today)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Today)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreSeriesData2 = chartStats !== undefined && chartStats?.week?.stats.length > 0 ? chartStats?.week?.stats: [];
  	const salesByStoreOptionsChart2 = {
    label: 'Thursday - Wednesday',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.week?.filter.length > 0  ? chartStats?.week?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.week?.categories.length > 0 ? chartStats?.week?.categories: [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Last 7 Days)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Last 7 Days)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Last 7 Days)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Last 7 Days)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Last 7 Days)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreOptionsChart222 = {
		label: 'Thursday - Wednesday',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.week?.filter.length > 0  ? chartStats?.week?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.week?.categories.length > 0 ? chartStats?.week?.categories: [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Last 7 Days)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Last 7 Days)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Last 7 Days)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Last 7 Days)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Last 7 Days)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreSeriesData3 = chartStats !== undefined && chartStats?.month?.stats.length > 0 ? chartStats?.month?.stats: [];
  	const salesByStoreOptionsChart3 = {
    label: 'November 15, 2022 - December 14, 2022',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.month?.filter.length > 0  ? chartStats?.month?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.month?.categories.length > 0 ? chartStats?.month?.categories: [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Last 30 Days)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Last 30 Days)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Last 30 Days)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Last 30 Days)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Last 30 Days)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreOptionsChart333 = {
		label: 'November 15, 2022 - December 14, 2022',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.month?.filter.length > 0  ? chartStats?.month?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.month?.categories.length > 0 ? chartStats?.month?.categories: [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Last 30 Days)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Last 30 Days)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Last 30 Days)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Last 30 Days)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Last 30 Days)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreSeriesData4 = chartStats !== undefined && chartStats?.year?.stats.length > 0 ? chartStats?.year?.stats: [];
	const salesByStoreOptionsChart4 = {
    label: 'December 2021 - November 2022',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.year?.filter.length > 0  ? chartStats?.year?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.year?.categories.length > 0 ? chartStats?.year?.categories: [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Last 12 Months)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Last 12 Months)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Last 12 Months)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Last 12 Months)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Last 12 Months)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};
	const salesByStoreOptionsChart444 = {
		label: 'December 2021 - November 2022',
		chart: {
			height: 400,
			type: 'line',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: [3, 1, 1, 1, 1],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '35px',
			},
		},
		filter: {
			categories: chartStats !== undefined && chartStats?.year?.filter.length > 0  ? chartStats?.year?.filter :"",
		},
		xaxis: {
			categories: chartStats !== undefined && chartStats?.year?.categories.length > 0 ? chartStats?.year?.categories: [],
		},
		yaxis: [
			{
				seriesName: 'Delivered',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#46bcaa",
				},
				labels: {
					style: {
						colors: "#46bcaa",
					},
				},
				title: {
					text: 'Total Delivered (Last 12 Months)',
					style: {
						color: "#46bcaa",
					},
				},
			},
			{
				seriesName: 'Sent',
				show:false,
				opposite: false,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#4d69fa",
				},
				labels: {
					style: {
						colors: "#4d69fa",
					},
				},
				title: {
					text: 'Total Sent (Last 12 Months)',
					style: {
						color: "#4d69fa",
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Failed',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#f35421",
				},
				labels: {
					style: {
						colors: "#f35421",
					},
				},
				title: {
					text: 'Total Failed (Last 12 Months)',
					style: {
						color: "#f35421",
					},
				},
			},
			{
				seriesName: 'Undefined',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#ffcf52",
				},
				labels: {
					style: {
						colors: "#ffcf52",
					},
				},
				title: {
					text: 'Total Undefined (Last 12 Months)',
					style: {
						color: "#ffcf52",
					},
				},
			},
			{
				seriesName: 'Pending',
				show:false,
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: "#6c5dd3",
				},
				labels: {
					style: {
						colors: "#6c5dd3",
					},
				},
				title: {
					text: 'Total Pending (Last 12 Months)',
					style: {
						color: "#6c5dd3",
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
		},
	};

	function calculate(val) {
		const num = parseFloat(val); // Read Value from DOM
		const rounded = num.toFixed(2); // Round Number
		return rounded;
	}

	const percentValue =(a:number, b:number)=> {
        const value = a;
        const full = b;
        const percent = Math.round(value/full*100);
        return percent
    }

	const defaultOptions = {
		animDuration: 1000,
		showValue: true,
		initialValue: 0,
		min: 0,
		stroke:4,
		dialStartAngle: 180,
		dialEndAngle: 0,
		radius:0,
		max:sent
		// Put any other defaults you want. e.g. dialStartAngle, dialEndAngle, radius, etc.
	};

	const Gauge = props => {
		const gaugeEl = useRef(null);
		const gaugeRef = useRef(null);
		useEffect(() => {
		  if (!gaugeRef.current) {
			const options = { ...defaultOptions, ...props };
			gaugeRef.current = SvgGauge(gaugeEl.current, options);
			gaugeRef.current.setValue(options.initialValue);
		  }
		  gaugeRef.current.setValueAnimated(props.value, 1);
		}, [props]);
	
		return <div ref={gaugeEl} className="gauge-container" />;
	};

	const changeDdState =(e: any)=> {
		const id = e.target.value;
		if(id === "1") {
			setActiveCompanyTab("Today");
			console.log(id, activeCompanyTab)
		} else if(id === "2") {
			setActiveCompanyTab("This Week");
			console.log(id, activeCompanyTab)
		} else if(id === "3") {
			setActiveCompanyTab("This Month");
			console.log(id, activeCompanyTab)
		} else if(id === "4") {
			setActiveCompanyTab("This Year");
			console.log(id, activeCompanyTab)
		} 
	}

	const networkChartOption = {
		series: [{
			name: 'Total Sent',
			data: topNetworks?.data
		  }],
		label: 'Network Stats',
		chart: {
			height: 300,
			type: 'bar',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
			"#4d69fa",
			"#46bcaa",
			"#f35421",
			"#ffcf52",
			"#6c5dd3",
		],
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '30px',
			},
			dataLabels: {
				position: 'bottom', // top, center, bottom
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: topNetworks?.categories,
			position: 'bottom',
			axisBorder: {
			  show: false
			},
			axisTicks: {
			  show: false
			},
			crosshairs: {
			  fill: {
				type: 'gradient',
				gradient: {
				  colorFrom: '#D8E3F0',
				  colorTo: '#BED1E6',
				  stops: [0, 100],
				  opacityFrom: 0.3,
				  opacityTo: 0.6,
				}
			  }
			},
			tooltip: {
			  enabled: true,
			}
		},
		yaxis: {
			axisBorder: {
			  show: false
			},
			axisTicks: {
			  show: false,
			},
			labels: {
			  show: false,
			  formatter: function (val:any) {
				return val;
			  }
			}
		  
		},
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		// legend: {
		// 	horizontalAlign: 'left',
		// 	offsetX: 40,
		// },
		title: {
			text: 'Network Stats',
			floating: true,
			offsetY: 300,
			align: 'center',
			style: {
			  color: '#444'
			}
		}
	};

	const contriesChartOption = {
		series: [{
			name: 'Total Sent',
			data: topCountries?.data
		  }],
		label: 'Country Stats',
		chart: {
			height: 300,
			type: 'bar',
			stacked: false,
			toolbar: { show: false },
		},
		colors: [
			"#677ffa",
			"#00af9f",
			"#f56d42",
			"#f3c651",
			"#8175d9",
			"#03a9f4",
			"#ff9800",
			"#8bc34a",
			"#607d8b",
			"#9c27b0",
		],
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '30px',
				distributed: true,
			},
			dataLabels: {
				position: 'top', // top, center, bottom
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: topCountries?.categories,
			labels: {
				style: {
				  colors: [
					"#677ffa",
					"#00af9f",
					"#f56d42",
					"#f3c651",
					"#8175d9",
					"#03a9f4",
					"#ff9800",
					"#8bc34a",
					"#607d8b",
					"#9c27b0",
				  ],
				  fontSize: '12px'
				}
			},
			position: 'bottom',
			axisBorder: {
			  show: false
			},
			axisTicks: {
			  show: false
			},
			crosshairs: {
			  fill: {
				type: 'gradient',
				gradient: {
				  colorFrom: '#D8E3F0',
				  colorTo: '#BED1E6',
				  stops: [0, 100],
				  opacityFrom: 1,
				  opacityTo: 1,
				}
			  }
			},
			tooltip: {
			  enabled: true,
			}
		},
		yaxis: {
			axisBorder: {
			  show: false
			},
			axisTicks: {
			  show: false,
			},
			labels: {
			  show: false,
			  formatter: function (val:any) {
				return val;
			  }
			}
		  
		},
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: true,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			show: false,
		},
		title: {
			text: 'Country Stats',
			floating: true,
			offsetY: 300,
			align: 'center',
			style: {
			  color: '#444'
			}
		}
	};

  return(
  <>
    {loading &&
      <Loader />
    }
    {/* begin::Row */}
    <div className='container-xxl dashboard-block'>

		<div className="row gy-5 g-xl-10 hb1 mb-10">
			<div className="col-md-12">
				<div className='card h-lg-100'>
					<div className='card-body'>
						<div className="row">
							<div className="col-md-7">
								<div className="row head-chart">
									<div className="col-md-4 full-sent text-center">
										{
											cload === true ? 
											<>
											<Gauge
												value={sent}
												min={0}
											/>
											<div className="chart-title mt-2 mb-2">{intl.formatMessage({id: 'DASHBOARD.TOTALSENT'})}</div>
											</> : 
											<div className='cload'><i className='fa fa-spin fa-spinner'></i></div>
										}	
									</div>
									<div className="col-md-4 full-delivered text-center">
										{
											cload === true ? 
											<>
											<Gauge
												value={delivered}
												min={0}
											/>
											<div className="chart-title mt-2 mb-2">{intl.formatMessage({id: 'DASHBOARD.TOTALDELIVERED'})}</div>
											</> :
											<div className='cload'><i className='fa fa-spin fa-spinner'></i></div>
										}
									</div>
									<div className="col-md-4 full-pending text-center">
									{
											cload === true ? 
											<>
											<Gauge
												value={pending}
												min={0}
											/>
											<div className="chart-title mt-2 mb-2">{intl.formatMessage({id: 'DASHBOARD.TOTALPENDING'})}</div>
											</> :
											<div className='cload'><i className='fa fa-spin fa-spinner'></i></div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-5">
								<div className='row dstats-block'>
									<div className='col-md-6'>
										<div className='dashboard-stat'>
											<div className='status-title'>{intl.formatMessage({id: 'DASHBOARD.CONTACTLISTS'})}</div>
											<div className='status-count'>{list} / <span>∞</span></div>
										</div>
										<div className='dashboard-stat'>
											<div className='status-title'>{intl.formatMessage({id: 'DASHBOARD.TOTALCONTACTS'})}</div>
											<div className='status-count'>{contacts} / 
											 {
												totalContacts === "-1" ? 
												<span>∞</span> : 
												totalContacts === -1 ?
												<span>∞</span> : 
												<>{totalContacts}</>
											}</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='dashboard-stat'>
											<div className='status-title'>{intl.formatMessage({id: 'DASHBOARD.TOTALBROADCASTS'})}</div>
											<div className='status-count'>{broadcasts} / <span>∞</span></div>
										</div>
										<div className='dashboard-stat'>
											<div className='status-title'>{intl.formatMessage({id: 'DASHBOARD.TOTALCAMPAIGNS'})}</div>
											<div className='status-count'>{campaigns} / {totalCampaigns}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="row gy-5 g-xl-10 hb1 hide">
			<div className="col-sm-6 col-xl-2 mb-xl-10">
				<div className='card h-lg-100'>
					<div className='card-body d-flex justify-content-between align-items-start flex-column'>
						<div className="m-0">
							<span className="svg-icon svg-icon-2hx svg-icon-gray-600">
							<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 24 24">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<rect id="bound" x="0" y="0" width="24" height="24"/>
									<circle id="Oval-47" fill="currentColor" opacity="0.3" cx="20.5" cy="12.5" r="1.5"/>
									<rect id="Rectangle-162" fill="currentColor" opacity="0.3" transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) " x="3" y="3" width="18" height="7" rx="1"/>
									<path d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z" id="Combined-Shape" fill="currentColor"/>
								</g>
							</svg>
							</span>
						</div>
						<div className="d-flex flex-column mt-5">
							<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2"><span className='fs-4'>$</span>{calculate(balance)}</span>
							<div className="m-0">
							<span className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({id: 'DASHBOARD.REMAININGBALANCE'})}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-sm-6 col-xl-2 mb-xl-10">
				<div className='card h-lg-100'>
					<div className='card-body d-flex justify-content-between align-items-start flex-column'>
					<div className="m-0">
						<span className="svg-icon svg-icon-2hx svg-icon-gray-600">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"></path>
							<path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"></path>
						</svg>
						</span>
					</div>
					<div className="d-flex flex-column mt-5">
						<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{list}</span>
						<div className="m-0">
						<span className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({id: 'DASHBOARD.CONTACTLISTS'})}</span>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div className="col-sm-6 col-xl-2 mb-xl-10">
				<div className='card h-lg-100'>
					<div className='card-body d-flex justify-content-between align-items-start flex-column'>
					<div className="m-0">
						<span className="svg-icon svg-icon-2hx svg-icon-gray-600">
						<svg width="33" height="33" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path><path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path><rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect></svg>
						</span>
					</div>
					<div className="d-flex flex-column mt-5">
						<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{contacts}</span>
						<div className="m-0">
						<span className="fw-semibold fs-6 text-gray-400" dir="rtl" lang="ar">{intl.formatMessage({id: 'DASHBOARD.TOTALCONTACTS'})}</span>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div className="col-sm-6 col-xl-2 mb-xl-10">
				<div className='card h-lg-100'>
					<div className='card-body d-flex justify-content-between align-items-start flex-column'>
					<div className="m-0">
						<span className="svg-icon svg-icon-2hx svg-icon-gray-600">
						<svg xmlns="http://www.w3.org/2000/svg" height="33" viewBox="0 0 24 24" width="33"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M7 5h10v14H7z" opacity="0.3" fill="currentColor"></path><path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z" fill="currentColor"></path></svg>
						</span>
					</div>
					<div className="d-flex flex-column mt-5">
						<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{sent}</span>
						<div className="m-0">
						<span className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({id: 'DASHBOARD.TOTALSENT'})}</span>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div className="col-sm-6 col-xl-2 mb-xl-10">
				<div className='card h-lg-100'>
					<div className='card-body d-flex justify-content-between align-items-start flex-column'>
					<div className="m-0">
						<span className="svg-icon svg-icon-2hx svg-icon-gray-600">
						<svg xmlns="http://www.w3.org/2000/svg" height="33" viewBox="0 0 24 24" width="33"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M4 17.17L5.17 16H20V4H4v13.17zM15 9h2v2h-2V9zm-4 0h2v2h-2V9zM7 9h2v2H7V9z" opacity="0.3" fill="currentColor"></path><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17L4 17.17V4h16v12zM7 9h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2z" fill="currentColor"></path></svg>
						</span>
					</div>
					<div className="d-flex flex-column mt-5">
						<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{delivered}</span>
						<div className="m-0">
						<span className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({id: 'DASHBOARD.TOTALDELIVERED'})}</span>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div className="col-sm-6 col-xl-2 mb-xl-10">
				<div className='card h-lg-100'>
					<div className='card-body d-flex justify-content-between align-items-start flex-column'>
					<div className="m-0">
						<span className="svg-icon svg-icon-2hx svg-icon-gray-600">
						<svg xmlns="http://www.w3.org/2000/svg" height="33" viewBox="0 0 24 24" width="33"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16.1 10.44l1.5-4.05-4.05 1.5 2.55 2.55zm-1.53 4.13L9.43 9.43l-2.71 1.01 4.89 1.95 1.95 4.89 1.01-2.71z" opacity="0.3" fill="currentColor"></path><path d="M12 6.34L21 3l-3.34 9-1.56-1.56 1.5-4.05-4.05 1.5L12 6.34zm9.19 14.85l-5.07-5.07L14.31 21H12.9l-2.83-7.07L3 11.1V9.69l4.88-1.81-5.07-5.07L4.22 1.4 22.6 19.78l-1.41 1.41zm-6.62-6.62L9.43 9.43l-2.71 1.01 4.89 1.95 1.95 4.89 1.01-2.71z" fill="currentColor"></path></svg>
						</span>
					</div>
					<div className="d-flex flex-column mt-5">
						<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{pending}</span>
						<div className="m-0">
						<span className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({id: 'DASHBOARD.TOTALPENDING'})}</span>
						</div>
					</div>
					</div>
				</div>
			</div>
		</div>

		<div className="row g-5 g-xl-8 mb-10">
			<div className='col-xl-12'>
				<div className='card card-xl-stretch pt-4'>
					<div className='card-header align-items-center border-0'>
					<h3 className='card-title align-items-start flex-column'>
						<span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'DASHBOARD.CAMPAIGNSTATS'})}</span>
						<span className='text-muted fw-semibold fs-7'>
							{intl.formatMessage({id: 'DASHBOARD.STATISTICS'})} 
							<small style={{color: `grey`, fontWeight:600, marginLeft:5}}>
								{activeCompanyTab === COMPANIES_TAB.COMP1 ? <span>({chartStats?.today?.filter})</span> : null}
								{activeCompanyTab === COMPANIES_TAB.COMP2 ? <span>({chartStats?.week?.filter})</span> : null}
								{activeCompanyTab === COMPANIES_TAB.COMP3 ? <span>({chartStats?.month?.filter})</span> : null}
								{activeCompanyTab === COMPANIES_TAB.COMP4 ? <span>({chartStats?.year?.filter})</span> : null}
							</small>
						</span>
						{/* salesByStoreOptionsChart1?.label */}
					</h3>
					<div className="card-toolbar">
						<select className="form-select form-select-solid form-select-lg" onChange={changeDdState}>
							<option value="1">{intl.formatMessage({id: 'DASHBOARD.CAMPAIGNSFILTER1'})}</option>
							<option value="2">{intl.formatMessage({id: 'DASHBOARD.CAMPAIGNSFILTER2'})}</option>
							<option value="3">{intl.formatMessage({id: 'DASHBOARD.CAMPAIGNSFILTER3'})}</option>
							<option value="4">{intl.formatMessage({id: 'DASHBOARD.CAMPAIGNSFILTER4'})}</option>
						</select>
					</div>
					</div>
					<div className='card-body p-0 d-flex flex-column'>
						<div className='card px-5 pb-5 pt-0 bg-body flex-grow-1'>
							<div className='row'>
								<div className='col-xl-3 col-xxl-2 hide'>
									<div className='row g-3'>
										{companies.map((company) => (
											<div
												key={company.name}
												className='col-xl-12 col-lg-6 col-sm-12'>
												<button
													data-status={activeCompanyTab !== company.name}
													onClick={() =>
														setActiveCompanyTab(company.name)
													}
													className={`w-100 py-5 shadow bg-white outline-0 border-0 br-1 mb-3 state-${company.id}`}
													data-name={company.name}
													>
													<img
														src={company.img}
														alt={company.name}
														width='auto'
														height={24}
													/>
												</button>
											</div>
										))}
									</div>
								</div> 
								<div className='col-xl-12 col-xxl-12'>	
								{activeCompanyTab === COMPANIES_TAB.COMP1 ? 
									<>
									{
										windowsWidth < 768 ?
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData1}
											options={salesByStoreOptionsChart111}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/> :
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData1}
											options={salesByStoreOptionsChart1}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/>
									}
									</>
									: null
								}
								{activeCompanyTab === COMPANIES_TAB.COMP2 ? 
									<>
									{
										windowsWidth < 768 ?
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData2}
											options={salesByStoreOptionsChart222}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/> :
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData2}
											options={salesByStoreOptionsChart2}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/>
									}
									</>
										
									: null
								}
								{activeCompanyTab === COMPANIES_TAB.COMP3 ? 
									<>
									{
										windowsWidth < 768 ?
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData3}
											options={salesByStoreOptionsChart333}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/> :
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData3}
											options={salesByStoreOptionsChart3}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/>
									}
									</>
									: null
								}
								{activeCompanyTab === COMPANIES_TAB.COMP4 ?
									<>
									{
										windowsWidth < 768 ?
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData4}
											options={salesByStoreOptionsChart444}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/> :
										<Chart
											width="100%"
											className="final-chart"
											series={salesByStoreSeriesData4}
											options={salesByStoreOptionsChart4}
											type={salesByStoreOptions.chart.type}
											height={salesByStoreOptions.chart.height}
										/>
									}
									</> 
									: null
								}
								</div>
							</div>
								
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="row g-5 g-xl-8 ">

			<div className='col-xl-4 mb-6'>
				<div className='card card-xl-stretch bg-transparent'>
					<div className='card-body px-0 py-0'>
						<StatisticsWidget3 className="card-xl mb-6 credit-chart" dataVal={l5campPrice} title={intl.formatMessage({id: 'DASHBOARD.CREDITS'})} description={intl.formatMessage({id: 'DASHBOARD.LASTCAMPAIGNS'})} color="success" />
						<MixedWidget7 className="card-xl ctr-chart" chartVal={percentValue(delivered, sent)} title={intl.formatMessage({id: 'DASHBOARD.CTR'})} description={intl.formatMessage({id: 'DASHBOARD.ALLCTR'})} chartColor="success" chartHeight="170" />
					</div>
				</div>
			</div>
			
			<div className='col-xl-4 mb-6'>
				<div className='card card-xl-stretch height-100'>
					<div className='card-header align-items-center border-0 mt-4'>
						<h3 className='card-title align-items-start flex-column'>
							<span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'DASHBOARD.RECENTACTIVITIES'})}</span>
							<span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'DASHBOARD.LAST'})} {lastIndex(logs)} {intl.formatMessage({id: 'DASHBOARD.ACTIVITIES'})}</span>
						</h3>
						<div className="card-toolbar"></div>
					</div>
					<div className='card-body pt-2 max-height-410'>
						{
						logs !== undefined && logs.length > 0 ? <div className='timeline-label  scroll-y'>{logs?.map((act, index) => {
							return(
							<div className='timeline-item' key={act.id}>
								<div className="timeline-label fw-bold text-gray-800 fs-6">{moment(act.created_at).add(currentUser?.time_zone, 'hours').fromNow()}</div>
								<div className="timeline-badge"><i className={`fa fa-genderless text-${color(index,)} fs-1`}></i></div>
								<div className="fw-mormal timeline-content text-muted ps-3">{act.module}</div>
							</div>
							)
						})}</div> : <div className='ra-no-data'> 
							<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon svg-icon-4x text-grey"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" fill="#6c757d"></path></svg> {intl.formatMessage({id: 'DASHBOARD.NODATA'})}
							</div>
						}
					</div>
				</div>
			</div>

			<div className='col-xl-4 mb-6'>
				<ListsWidget4 className='card-xl-stretch' />
			</div>

		</div>

		<div className="row g-5 g-xl-8 ">

			<div className='col-xl-4 mb-6'>
				<div className='card card-xl-stretch height-100'>
					<div className='card-header align-items-center border-0 mt-4'>
						<h3 className='card-title align-items-start flex-column'>
							<span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'DASHBOARD.RECENTLOGS'})}</span>
							<span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'DASHBOARD.LAST'})} {lastIndex(activities)} {intl.formatMessage({id: 'DASHBOARD.ACTIVITIES'})}</span>
						</h3>
						<div className="card-toolbar"></div>
					</div>
					<div className='card-body pt-2 max-height-300 login-activities'>
						<div className="timeline-label no-timeline scroll-y" key="dbact-123">
							{activities !== undefined && activities.length > 0 ? 
							activities?.map((act, index) => {
								return(
									<div className="d-flex align-items-center" key={`la-${index}`}>
										<div className="symbol symbol-30px me-5">
											<span className="symbol-label">  
												<i className={`fa fa-angle-double-right text-primary fa-lg`}></i>                             
											</span>                
										</div>
										<div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
											<div className="me-5">
												<div className="text-gray-800 fw-bold text-hover-primary fs-6">{act?.module} {moment(act.created_at).add(currentUser?.time_zone, 'hours').fromNow(true)} ago</div>
												<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">{moment(act.created_at).format('lll')}</span>
											</div>  
										</div>                             
									</div>
								)
							}) : 
							<div className='ra-no-data'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon svg-icon-4x text-grey"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" fill="#6c757d"></path></svg> {intl.formatMessage({id: 'DASHBOARD.NODATA'})}</div>
							}							
						</div>
					</div>
				</div>	
			</div>

			<div className='col-xl-4 mb-6'>
				<div className='card card-xl-stretch height-100'>
					<div className='card-header align-items-center border-0 mt-4'>
						<h3 className='card-title align-items-start flex-column'>
							<span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'DASHBOARD.NETWORKSTATS'})}</span>
							<span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'DASHBOARD.TOPNETWORKSTATS'})}</span>
						</h3>
						<div className="card-toolbar"></div>
					</div>
					<div className='card-body pt-2 max-height-300'>
						<div className="top-20">
							{
								networkFlag === false ?
								<div className='ra-no-data'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon svg-icon-4x text-grey"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" fill="#6c757d"></path></svg> {intl.formatMessage({id: 'DASHBOARD.NODATA'})}</div> 
								:  <Chart 
									className="col-md-12"
									options={networkChartOption} 
									series={networkChartOption?.series} 
									type="bar" 
									width="100%"
									height={300} 
								/>
							}
						</div>
					</div>
				</div>	
			</div>

			<div className='col-xl-4 mb-6'>
				<div className='card card-xl-stretch height-100'>
					<div className='card-header align-items-center border-0 mt-4'>
						<h3 className='card-title align-items-start flex-column'>
							<span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'DASHBOARD.COUNTRYSTATS'})}</span>
							<span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'DASHBOARD.TOPCOUNTRYSTATS'})}</span>
						</h3>
						<div className="card-toolbar"></div>
					</div>
					<div className='card-body pt-2 max-height-300'>
						<div className="top-20">
							{
								countryFlag === false ?
								<div className='ra-no-data'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon svg-icon-4x text-grey"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" fill="#6c757d"></path></svg> {intl.formatMessage({id: 'DASHBOARD.NODATA'})}</div> 
								: <Chart 
									className="col-md-12"
									options={contriesChartOption} 
									series={contriesChartOption?.series} 
									type="bar" 
									width="100%"
									height={300} 
								/>
							}
								
						</div>
					</div>
				</div>	
			</div>

		</div>

      {/* <ChartsWidget8 className="card-xl-stretch mb-5 mb-xl-8" /> */}

    </div>
  </>
)
}

const DashboardWrapper: FC = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
