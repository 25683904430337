import React, {useState, useEffect, useCallback} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import axios from 'axios'
// import {HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
// import { useNavigate } from 'react-router-dom'
// import {useLayout} from '../../core'
import { useAuth } from '../../../../app/modules/auth'
// import moment from 'moment'

const serverUrl = process.env.REACT_APP_SERVER_URL

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  // const navigate = useNavigate();
  // const {config} = useLayout()
  
  const API_URL = process.env.REACT_APP_BASE_URL
  const {currentUser} = useAuth()
  let loggedInUser = JSON.parse(localStorage.getItem('user'));
  let photo2 = loggedInUser.photo;
  var photo = `${serverUrl}/storage/users/${currentUser?.id}/profile/${photo2}`;
  localStorage.setItem("notify", String(loggedInUser?.notification_count))
  // let un_count = loggedInUser?.notification_count;
  const [showNotify, setShowNotify] = useState(false);
  const [snFlag, setSnFlag] = useState(0);
  const [uncCount, setUncCount] = useState(loggedInUser?.notification_count);
  // const [cr, setCr] = useState(0);
  // const [crdBtn, setCrdBtn] = useState(false);

  // const user = JSON.parse(localStorage.getItem('user'));
  // const date = new Date().toLocaleTimeString();
  // const updatedTime = moment(date).add(user?.time_zone, 'hours').utc().format('dddd MMMM DD, YYYY hh:mm A');

  // const [state, setState] = useState({
  //   time: null
  // });

  // console.log(d as any);

  const getNotificationCount = useCallback(async () => { 
    await axios.post(`${API_URL}/getClientNotificationCount`, { user_id: currentUser?.id, api_token: currentUser?.token }, { 
    }).then(res => {
      if (res.data.status === 'success') {
        setSnFlag(res.data.notification_count)
      } else {
        setSnFlag(0)
      }
    })

  }, [currentUser, API_URL]);

  // useEffect(() => {
  //   setInterval(getNotificationCount, 180000);
  // }, []);

  

  useEffect(() => {
    const newNotify = JSON.parse(localStorage.getItem('notify'));
    if(newNotify) {
      if(snFlag > newNotify) {
        setShowNotify(true)
      } else {
        setShowNotify(false)
      }
    } 
  }, [snFlag, uncCount]);

  const hideDot = () => {
    setShowNotify(false)
    setUncCount(snFlag);
    localStorage.setItem("notify", String(snFlag))
    setTimeout(() => {
      setShowNotify(false)
    }, 1000);
  }
  
  // useEffect(() => {
  //   setInterval(() => {
  //       setState({...state, time:new Date().toLocaleTimeString()});
  //   }, 1000);
  // }, [state]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCr(Number(localStorage.getItem('cr')));
  //     if(cr !== undefined && cr === 0) {
  //       setCrdBtn(false)
  //     } else if (cr < 10) {
  //       setCrdBtn(true)
  //     } else {
  //       setCrdBtn(false)
  //     }
  //   }, 4000);
	// }, [cr]);
  // setInterval(() => {
  //   photo = `${serverUrl}/storage/users/${currentUser?.id}/profile/${photo2}`;
  // }, 5000);

  useEffect(() => {
    setInterval(getNotificationCount, 240000);
  }, [getNotificationCount]);

  return (
    <div className='app-navbar flex-shrink-0'>

      {/* {
        crdBtn !== false ?
        <div className={clsx('app-navbar-item me-2 min-w-150px', itemClass)}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            className={`min-w-150px `+btnClass}
          >
            <button className='btn btn-danger btn-sm br-2' onClick={() => navigate('/billing/balance/add')}>
              <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" className="text-white"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11.5 17.1c-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79z" fill="#FFF"></path></svg>
              Low Balance
            </button>
          </div>
        </div>
        : null
      } */}

      
      {/* <div className="d-flex align-items-center">
        <span className="text-dark-75 dont-weight-bold mr-4 ml-3 global-clock">
          <i className="fa fa-regular fa-clock"></i> {state.time}
        </span>
      </div> */}
        
      
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative btn-gn', btnClass)} id='kt_drawer_chat_toggle' onClick={hideDot} data-flag={snFlag}>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          {
            showNotify &&
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          }
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {
            currentUser?.photo !== null ?
            <img alt='Logo' src={photo} />
            : <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          }
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
