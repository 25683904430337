/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect, useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import axios from 'axios'
import {IQuickSend, quickSendInitValues as initialValues} from './AddQsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { useAuth } from '../../modules/auth';
import Toastr from '../../Toastr/Toastr'
import Loader from '../../Loader/Loader'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

const profileDetailsSchema = Yup.object().shape({
  tags: Yup.string().required('Mobile Number is required'),
  message: Yup.string().required('Message is required'),
})

const AddQSWrapper: FC = () => {

  const intl = useIntl()
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'BREADCRUMBS.HOME'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_BASE_URL
  const {currentUser, logout} = useAuth()
  const [loading, setLoading] = useState(true);
  const [senderVal, setSenderVal] = useState("mumara");
	const [senderType, setSenderType] = useState(senderVal);
  const [serverError, setServerError] = useState("");
  const [showCSid, setShowCSid] = useState(false); 
  const [senderIds, setSenderIds] = useState([]);
  const [gidTypeData, setGidTypeData] = useState([]); 
  const [sid, setSid] = useState(null); 

  const [state, setState] = React.useState({
    toast:false,
    toastType: "success",
    toastMessage: "Message successfully sent!",
    senderid: "",
    sidtValid: '',
    sidValid: '',
    numberValid: "",
    msgValid:"",
    csidValid: ""
	});

  const handleTypeChange =(e)=> {
		const { value } = e.target;		
    // console.log(value)
    setSenderType(value);	
    setServerError("");
    setShowCSid(false);
    setSenderVal(value);
    getRecords(value);
	} 

  const getRecords = useCallback(async (senderType) => {

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: currentUser?.id, api_token: currentUser?.token, sender_id_type:0, provider_type: senderType})
    };

    setLoading(true);
    const res = await fetch(`${API_URL}/getAllSenderIDs`, requestOptions);
    const dataRes = await res.json();
    if(dataRes?.status === 'false' && dataRes?.code === 710) {
      Swal.fire({
        icon: 'warning',
        title: 'Session expired, <br /> please login again to continue.<br /><br />',
        showConfirmButton: false,
        timer: 2000
      });
      logout();
      navigate('/auth/login');
      setState({...state, toast:true, toastMessage:"Your Session has been expired, please login again.", toastType: "error"});
      setTimeout(() => {
        setState({...state, toast:false});
      }, 2000);
    } else if (dataRes?.status === 'success') {
        // console.log(dataRes.data);
        setSenderIds(dataRes.data);
        // console.log(senderIds)
        setLoading(false);
    } else {
        setSenderIds([]);
        setLoading(false);
    }
    setLoading(false);
    
  }, [currentUser, logout, API_URL, navigate, state]);

  useEffect(() => {
    if (currentUser === undefined) {
      logout();
    }
    getRecords(senderVal);
  }, [currentUser, logout, getRecords, senderVal]);
  
  const formik = useFormik<IQuickSend>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const listData= {user_id: currentUser?.id, api_token: currentUser?.token, mobile_numbers: values.tags, senderID:sid, provider_type: senderType, message: values.message}
      axios.post(`${API_URL}/quickSendSMS`, listData, { // receive two parameter endpoint url ,form data 
      })
      .then(dataRes => {
        if (dataRes.data.status === 'success') {
            setState({...state, toast:true, toastMessage:dataRes.data.message, toastType: "success"});
            setLoading(false);
            setTimeout(() => {
              setState({...state, toast:false});
              navigate('/');
            }, 2000)

        } else if(dataRes.data.message === "Unauthorised") {
          Swal.fire({
            icon: 'warning',
            title: 'Session expired, <br /> please login again to continue.<br /><br />',
            showConfirmButton: false,
            timer: 2000
          });
          logout();
        } else {
            setLoading(false);
            setState({...state, toast:true, toastMessage:dataRes.data.message, toastType: "error"});
            setTimeout(() => {
              setState({...state, toast:false});
            }, 2000);
        }
      })
    },
  });

  const gidTypeChange = (e) => {
    // console.log(e.target.value)
    const cusTVal = e.target.value;
    const customDD = senderIds?.filter(function(item){
        return !cusTVal.indexOf(item.other_api_id)
    });

    setGidTypeData(customDD)
    if(cusTVal > 0)  {
      setShowCSid(true)
    } else {
      setShowCSid(false)
    }
    setState({ ...state, numberValid:"", msgValid: ""});
  }

  const uniqueId =(array)=> {
    const uniqueIds = [];
    const unique = array.filter(element => {
        const isDuplicate = uniqueIds.includes(element.other_api_id);
      
        if (!isDuplicate) {
            uniqueIds.push(element.other_api_id);
          return true;
        }          
        return false;
      });
    return unique;  
  }

  const changeSenderID =(e)=> {
    setSid(e.target.value);
  }

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'MENU.QUICKSEND'})}</PageTitle>
      {
        state.toast === true ?
        <Toastr content={state.toastMessage} type={state.toastType} onClick={() => setState({...state, toast:false})} />
        : <></>
      }
      {loading &&
        <Loader />
      }
      <div className='card mb-5 mb-xl-10 col-md-6'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'MENU.QUICKSEND'})}</h3>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          
          <input type="hidden" {...formik.getFieldProps('uid')} value={currentUser?.id} />
          <input type="hidden" {...formik.getFieldProps('token')} value={currentUser?.token} />

          <div className='card-body border-top p-9'>

            {serverError !== "" ? <div className="alert alert-danger" >
                {serverError}
            </div> : ""}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'QUICKSEND.MOBILENUMBERS'})}</label>

              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Mobile Numbers'
                  {...formik.getFieldProps('tags')}
                />
                <div className='badge  text-muted'>{intl.formatMessage({id: 'QUICKSEND.MULTIPLEMOBILENUMBERS'})}</div>
                {formik.touched.tags && formik.errors.tags && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.tags}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'BROADCASTS_HEADER.SENDERIDTYPE'})}</label>

              <div className='col-lg-8'>
                <label className='col-form-label fw-bold me-5 mb-5 cursor-pointer'>
                  <input 
                    type="radio" 
                    className='form-check-input cursor-pointer'
                    name='provider_type' 
                    value="mumara"
                    defaultChecked
                    onChange={handleTypeChange}
                    onClick={handleTypeChange}
                  />
                  <span className='fw-bold ps-2 fs-6' >{intl.formatMessage({id: 'BROADCASTS_HEADER.MUMARA'})}</span>
                </label>
                <label className='col-form-label fw-bold me-5 mb-5 cursor-pointer'>
                  <input 
                    type="radio" 
                    className='form-check-input cursor-pointer'
                    name='provider_type' 
                    value="custom"
                    onChange={handleTypeChange}
                    onClick={handleTypeChange}
                  />
                  <span className='fw-bold ps-2 fs-6' >{intl.formatMessage({id: 'BROADCASTS_HEADER.CUSTOM'})}</span>
                </label>
              </div>
            </div>

            {
              senderType === "custom" ?
                <div className={`row mb-6 ${state.sidtValid}`}>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'BROADCASTS_HEADER.SENDERIDTYPE'})}</label>
                  <div className='col-lg-8'>
                    {
                      senderIds !== undefined && senderIds?.length > 0 ?
                      <>
                      <select className={`form-select form-select-solid form-select-lg ${state.sidValid}`} name='senderid' onChange={gidTypeChange}>
                          <option value="">{intl.formatMessage({id: 'QUICKSEND.SELECTSENDERIDTYPE'})}</option>
                          {
                              uniqueId(senderIds).map((xx) => {
                                  return(
                                      <option key={xx.id} value={xx.other_api_id}>{xx.gateway_name}</option>
                                  )
                              })
                          }
                      </select>
                      <span className='text-muted' style={{fontSize:11, paddingLeft: 5}}>{intl.formatMessage({id: 'QUICKSEND.SELECTSENDERIDSENDMESSAGE'})}</span>
                      </>
                      : <span className=' d-table badge badge-light mt-3'>{intl.formatMessage({id: 'QUICKSEND.NOSENDERIDADDED'})}</span>
                    }
                  </div>
                </div>
              : null
            }

            {
              senderType === "mumara" ?
                <div className={`row mb-6 ${state.sidtValid}`}>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'BROADCASTS_HEADER.SENDERIDTYPE'})}</label>
                  <div className='col-lg-8'>
                  {
                      senderIds !== undefined && senderIds?.length > 0 ?
                      <>
                      <select className={`form-select form-select-solid form-select-lg ${state.sidValid}`} name='senderid' onChange={changeSenderID}>
                        <option value="">{intl.formatMessage({id: 'QUICKSEND.SELECTSENDERID'})}</option>
                        {
                            senderIds?.map((item) => {
                                return(
                                    <option key={item.id} value={item.id}>{item.sender_id}</option>
                                )
                            })
                        }
                      </select>
                      <span className='text-muted' style={{fontSize:11, paddingLeft: 5}}>{intl.formatMessage({id: 'QUICKSEND.SELECTSENDERIDSENDMESSAGE'})}</span>
                      </>
                      : <span className=' d-table badge badge-light mt-3'>{intl.formatMessage({id: 'QUICKSEND.NOSENDERIDALLOWED'})}</span>
                    }
                  </div>
                </div>
              : null
            }

            {
              showCSid === true ? 
              <div className={`row mb-6 ${state.csidValid}`}>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'QUICKSEND.CUSTOMSENDERID'})}</label>
                <div className='col-lg-8'>
                  {
                    gidTypeData !== undefined && gidTypeData?.length > 0 ?
                    <>
                    <select className={`form-select form-select-solid form-select-lg ${state.sidValid}`} name='senderid' onChange={changeSenderID}>
                        <option value="">{intl.formatMessage({id: 'QUICKSEND.SELECTSENDERID'})}</option>
                        {
                            gidTypeData?.map((item) => {
                                return(
                                    <option key={item.id} value={item.id}>{item.sender_id}</option>
                                )
                            })
                        }
                    </select>
                    <span className='text-muted' style={{fontSize:11, paddingLeft: 5}}>{intl.formatMessage({id: 'QUICKSEND.SELECTSENDERIDSENDMESSAGE'})}</span>
                    </>
                    : <span className=' d-table badge badge-light mt-3'>{intl.formatMessage({id: 'BROADCASTS_HEADER.NO_AVAILABLE'})}</span>
                  }
                </div>
              </div>
              : null
            }

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'BROADCASTS_HEADER.MESSAGE'})}</label>

              <div className='col-lg-8'>
                <textarea
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Message'
                  {...formik.getFieldProps('message')}
                />
                {formik.touched.message && formik.errors.message && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.message}</div>
                  </div>
                )}
              </div>
            </div>


          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Send'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BROADCASTS_HEADER.PLEASEWAIT'})}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export {AddQSWrapper}