/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.PAGES'})}</span>
        </div>
      </div>

      <SidebarMenuItem 
        to='/quick-send' 
        title={intl.formatMessage({id: 'MENU.QUICKSEND'})} 
        fontIcon='bi-layers'
        icon='/media/icons/duotune/general/gen019.svg'
      />

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.LISTS'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        
        <SidebarMenuItem to='/lists/all' title={intl.formatMessage({id: 'MENU.MYLISTS'})} hasBullet={true} />
        <SidebarMenuItem to='/lists/custom-fields/all' title={intl.formatMessage({id: 'MENU.CUSTOMFIELDS'})} hasBullet={true} />
        <SidebarMenuItem to='/lists/suppressed/all' title={intl.formatMessage({id: 'MENU.SUPPRESSIONLIST'})} hasBullet={true} />
        {/* <SidebarMenuItem to='/lists/export' title='Export List' hasBullet={true} /> */}

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.CONTACTS'})}
        fontIcon='bi-person'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        
        <SidebarMenuItem to='/contacts/all' title={intl.formatMessage({id: 'MENU.VIEWCONTACTS'})} hasBullet={true} />
        <SidebarMenuItem to='/contacts/add' title={intl.formatMessage({id: 'MENU.ADDCONTACTS'})} hasBullet={true} />
        <SidebarMenuItem to='contacts/import' title={intl.formatMessage({id: 'MENU.IMPORTCONTACTS'})} hasBullet={true} />

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.BROADCASTS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        
        <SidebarMenuItem to='broadcasts/all' title={intl.formatMessage({id: 'MENU.VIEWBROADCASTS'})} hasBullet={true} />
        <SidebarMenuItem to='broadcasts/add' title={intl.formatMessage({id: 'MENU.ADDBROADCASTS'})} hasBullet={true} />
        <SidebarMenuItem to='broadcasts/scheduled' title={intl.formatMessage({id: 'MENU.VIEWSCHEDULED'})} hasBullet={true} />
        <SidebarMenuItem to='broadcasts/scheduled/add' title={intl.formatMessage({id: 'MENU.SCHEDULEDBROADCASTS'})} hasBullet={true} />

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.SENDERID'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/communication/com005.svg'
      >
        
        <SidebarMenuItem to='/senderids/all' title={intl.formatMessage({id: 'MENU.VIEWSENDERIDS'})} hasBullet={true} />
        <SidebarMenuItem to='/senderids/apply' title={intl.formatMessage({id: 'MENU.APPLYSENDERIDS'})} hasBullet={true} />
        <SidebarMenuItem to='/senderids/add' title={intl.formatMessage({id: 'MENU.ADDSENDERIDS'})} hasBullet={true} />

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.PROVIDERS'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/abstract/abs014.svg'
      >
        
        <SidebarMenuItem to='/providers/all' title={intl.formatMessage({id: 'MENU.VIEWPROVIDERS'})} hasBullet={true} />
        <SidebarMenuItem to='/providers/add' title={intl.formatMessage({id: 'MENU.ADDPROVIDERS'})} hasBullet={true} />

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.STATISTICS'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/general/gen032.svg'
      >
        
        <SidebarMenuItem to='/stats/broadcast' title={intl.formatMessage({id: 'MENU.BROADCASTSTATS'})} hasBullet={true} />
        <SidebarMenuItem to='/stats/transactional' title={intl.formatMessage({id: 'MENU.TRANSACTIONALSTATS'})} hasBullet={true} />
        <SidebarMenuItem to='/stats/global' title={intl.formatMessage({id: 'MENU.GLOBALSTATS'})} hasBullet={true} />

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.BILLING'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/general/gen005.svg'
      >
        
        <SidebarMenuItem to='billing/cards' title={intl.formatMessage({id: 'MENU.CREDITCARDS'})} hasBullet={true} />
        <SidebarMenuItem to='billing/invoices' title={intl.formatMessage({id: 'MENU.INVOICES'})} hasBullet={true} />

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.LOGS'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/abstract/abs027.svg'
      >
        
        <SidebarMenuItem to='logs/activity' title={intl.formatMessage({id: 'MENU.ACTIVITYLOGS'})} hasBullet={true} />
        <SidebarMenuItem to='logs/login' title={intl.formatMessage({id: 'MENU.LOGINLOGS'})} hasBullet={true} />
        <SidebarMenuItem to='logs/notifications' title={intl.formatMessage({id: 'MENU.NOTOFOCATIONLOGS'})} hasBullet={true} />

      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
